import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
      <div className="hero">
        <div className="front-content">
          <div className="container-mid">
            <div
              className="animation-container"
              data-animation-delay={300}
            >
              <h1>We're Coming Soon..</h1>
            </div>
            <div
              className="animation-container"
              data-animation-delay={600}
            >
              <p className="subline">
                We're working hard for you.
              </p>
            </div>
          </div>
          <div className="footer">
            <div
              className="animation-container"
              data-animation-delay={1200}
            >
              <p>
                © 2023 Gopani Electricals

              </p>
            </div>
          </div>
        </div>
        <div className="background-content parallax-on">
          <div className="background-overlay" />
          <div className="background-img layer" data-depth="0.05" />
        </div>
      </div>
    </>

  );
}

export default App;
